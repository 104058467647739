<template lang="pug">
div.container
  div.row.justify-content-center
    div.col-md-8
      h1 Update Details and/or Renew Membership
      p For security reasons we only allow updating membership details via a private link sent to the email address you registered with. Just fill in your email below and we'll get the link to you right away!
      p In case of any trouble please don't hesitate to contact us via #[a(href="mailto:members@igda.fi") members@igda.fi] and we'll figure it out together!
  br/
  br/
  div.row.justify-content-center.text-center(v-if="showResult")
    b-alert(show v-if="emailError" variant="danger") Oh snap! There was an error sending an email to #[strong {{email}}]. Please contact us at #[a(href="mailto:members@igda.fi") members@igda.fi] and we'll sort this out together.<br/>Details: {{emailError}}
    b-alert(show v-else variant="success") Success! Email sent to #[strong {{email}}] and should arrive shortly.
  div.row.justify-content-center.text-center(v-if="wrongEmail")
    b-alert(show v-if="emailError" variant="info") We couldn't find anyone with the email #[strong {{wrongEmailAddress}}]. Maybe you registered with something else? In case of trouble please contact us at members@igda.fi and we'll sort this out together.

  div.row(v-if="showSpinner")
    loading-spinner(message="Sending email...")

  div.row.justify-content-center(v-if="!hideForm")
    div.col-md-8
      h4.text-center Get a Membership Profile Link
      br/
      b-form
        div.row.justify-content-center
          div.col-sm-6
            b-form-group(label="Email Address" label-for="email")
              b-input(type="email" name="email" v-model="email" required)
        div.row.justify-content-center.text-center
          p(v-if="!isFormValid") Please fill in a valid email.
        div.row.justify-content-center
          b-button(:disabled="!isFormValid" variant="info" @click="sendEmail(email)") Request Link

</template>

<script>
export default {
  data: function () {
    return {
      hideForm: false,
      showSpinner: false,
      showResult: false,
      wrongEmail: false,
      emailError: null,
      email: '',
      wrongEmailAddress: ''
    }
  },
  computed: {
    isFormValid: function () {
      let result = true
      if (this.email === '') { result = false }
      return result
    }
  },
  methods: {
    sendEmail: function (email) {
      this.showSpinner = true
      this.hideForm = true
      this.emailError = false
      this.wrongEmail = false
      this.showResult = false

      this.axios.post('/api/cards/get-link', { email: this.email.toLowerCase().trim() })
        .then(res => {
          this.showResult = true
          this.showSpinner = false
        })
        .catch(err => {
          this.emailError = err
          this.showSpinner = false
          this.hideForm = false
          if (err.response.status === 400) {
            this.wrongEmail = true
            this.wrongEmailAddress = this.email
            this.email = ''
          } else { this.showResult = true }
        })
    }
  }
}
</script>
